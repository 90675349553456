import { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";

const useForeignCurrencyAnimationMobile = () => {
  const ANIMATION_TYPE = "ease-in-out";
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    setTimeout(() => {
      const rootContainerElement = document.getElementById("root_container");

      const currMobileElement = document.getElementById("currMobile_ref");
      const curr1MobileElement = document.getElementById("curr1Mobile_ref");
      const curr2MobileElement = document.getElementById("curr2Mobile_ref");
      const curr3MobileElement = document.getElementById("curr3Mobile_ref");

      gsap.to(currMobileElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: currMobileElement,
          start: "top center",
          toggleActions: "restart none none reverse",
        },
        width: "100%",
        borderRadius: 0,
        ease: ANIMATION_TYPE,
        duration: 0.6,
      });
      gsap.to(curr1MobileElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: currMobileElement,
          start: "top center",
          toggleActions: "restart none none reverse",
        },
        // x: 60,
        rotate: 0,
        left: 56,
        // top: 110,
        ease: ANIMATION_TYPE,
        duration: 0.6,
      });
      gsap.to(curr2MobileElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: currMobileElement,
          start: "top center",
          toggleActions: "restart none none reverse",
        },
        // x: 99,
        left: 120,
        // top: 110,
        rotate: 0,
        ease: ANIMATION_TYPE,
        duration: 0.6,
      });
      gsap.to(curr3MobileElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: currMobileElement,
          start: "top center",
          toggleActions: "restart none none reverse",
        },
        // x: 142,
        rotate: 0,
        // top: 110,
        left: 183,
        ease: ANIMATION_TYPE,
        duration: 0.6,
      });
    }, 300);
  }, []);
};

export default useForeignCurrencyAnimationMobile;
