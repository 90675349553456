import { useRouter } from "next/router";
import { useState } from "react";
import * as S from "./LandingCurrDel.style";
import LandingButton from "../LandingButton/LandingButton";
import { Markdown } from "../markdown";
import { Pincode } from "../pincode";
import useForeignCurrencyAnimationMobile from "../../hooks/useForeignCurrencyAnimationMobile";
import { pushEvent } from "@tm/utility/analytics";
import { Image } from "../Image";

const LandingCurrDel = ({
  foreignCurrencyBenefitsCard,
  foreignCurrencyEngagement,
  events,
}: any) => {
  useForeignCurrencyAnimationMobile();
  const [load] = useState(false);
  const router = useRouter();

  const { buyNowEvent, checkPincodeEvent } = events || {};

  const onClickHandler = () => {
    if (buyNowEvent) {
      pushEvent(buyNowEvent);
    }
    if (foreignCurrencyEngagement?.buyRedirectionUrl)
      router.push(foreignCurrencyEngagement.buyRedirectionUrl);
  };

  const { poweredBy, currencyImgs } = foreignCurrencyBenefitsCard;
  const { title, logos } = poweredBy || {};

  return (
    <S.LandingCurrDelParent>
      <S.LandingCurrDel id="currMobile_ref">
        <S.LandingCurrDelWrap id="padMobile_ref">
          <div>
            <S.LandingCurrDelTitle>
              {foreignCurrencyEngagement?.title}
            </S.LandingCurrDelTitle>
            <S.LandingCurrDelInfo>
              <Markdown>{foreignCurrencyEngagement?.subtitle}</Markdown>
            </S.LandingCurrDelInfo>
          </div>
          <S.LandingCurrDelLeft>
            <S.LandingCurrDelpara>
              <Markdown>{foreignCurrencyBenefitsCard?.title}</Markdown>
            </S.LandingCurrDelpara>
            <Image
              className="curr1"
              id="curr1Mobile_ref"
              {...currencyImgs?.[0]}
            />
            <Image
              className="curr2"
              id="curr2Mobile_ref"
              {...currencyImgs?.[1]}
            />
            <Image
              className="curr3"
              id="curr3Mobile_ref"
              {...currencyImgs?.[2]}
            />
            <S.LandingCurrDelList>
              <li>
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      foreignCurrencyBenefitsCard
                        .foreignCurrencyBenefitsList[0],
                  }}
                />
              </li>
              <li>
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      foreignCurrencyBenefitsCard
                        .foreignCurrencyBenefitsList[1],
                  }}
                />
              </li>
              <li>
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      foreignCurrencyBenefitsCard
                        .foreignCurrencyBenefitsList[2],
                  }}
                />
              </li>
            </S.LandingCurrDelList>
            <S.Flex>
              <S.PoweredByText>{title}</S.PoweredByText>
              {<Image {...logos?.[0]} width="107" height="32" />}
            </S.Flex>
            {foreignCurrencyEngagement?.buyCtaText && (
              <LandingButton
                margin="15px 0 0px"
                customTitle={foreignCurrencyEngagement?.buyCtaText}
                customWidth="100%"
                handleClick={onClickHandler}
              />
            )}
          </S.LandingCurrDelLeft>
          <div>
            <Pincode
              offering="BMF"
              text={foreignCurrencyEngagement?.deliveryInfo}
              load={load}
              ctaText={foreignCurrencyEngagement?.verifyPincodeCtaText}
              defaultDeliveryDays={
                foreignCurrencyEngagement.defaultDeliveryDays
              }
              onClick={(pincode) => {
                if (checkPincodeEvent?.cta) {
                  checkPincodeEvent.cta.pincode = pincode;
                  pushEvent(checkPincodeEvent);
                }
              }}
            />
          </div>
        </S.LandingCurrDelWrap>
      </S.LandingCurrDel>
    </S.LandingCurrDelParent>
  );
};

export default LandingCurrDel;
